body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* display: flex; */
}

html {
  font-family: 'Heebo', sans-serif;
}
@font-face {
  font-family: Butler;
  src: url(./assets/fonts/Butler_ExtraBold.otf);
  font-weight: '800';
}
@font-face {
  font-family: Butler;
  src: url(./assets/fonts/Butler_Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: Motiva Sans;
  src: url(./assets/fonts/MotivaSansRegular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: Motiva Sans;
  src: url(./assets/fonts/MotivaSansThin.ttf);
  font-weight: '100';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

button {
  font-family: inherit;
}

#root {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.highlight {
  letter-spacing: inherit;
}

/* iframe {
  z-index: -1 !important;
} */
