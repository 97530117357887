.screen {
  color: #001640;
  width: 100%;
  height: 100%;
  background-color: #f8faff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 11px;
  font-family: Heebo, Arial;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.modal {
  overflow: hidden;
  background-color: #ffffff;
  max-width: 48rem;
}

.modal-title {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  justify-content: center;
  align-items: center;
  font-size: 16pt;
}

.logo {
  width: 18rem;
}

.modal-body {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border: 1px solid #e5e7eb;
  border-top-width: 1px;
}

.modal-body span {
  color: #111827;
}

.modal-body dl {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 2rem;
}

.intro {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border: 0px solid #e5e7eb;
  border-top-width: 1px;
}

.intro span {
  color: #001640;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}

.one-column {
  grid-column: span 1 / span 1;
}

.two-columns {
  grid-column: span 2 / span 2;
}

.column-title {
  color: #6b7280;
  font-size: 0.875rem;
  font-weight: 500;
  display: block;
}

.column-body {
  color: #111827;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.footer {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  gap: 1rem;
}

.icon {
  width: 75px;
  height: 75px;
  margin-bottom: 6px;
  margin-left: 10px;
  color:#d0d5dd;
}

.button {
  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #001640;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  align-items: center;
  border-radius: 0.375rem;
  cursor: pointer;
  border-width: 1px;
  border-color: transparent;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.download-icon {
  width: 2rem;
  height: 2rem;
}